<template>
  <div>
    <img :src="img" alt="" />
    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" class="mb-2 mt-2" variant="success" @click="edit()">
      <feather-icon icon="EditIcon" class="mr-25" />
      <span>Nueva Imagen</span>
    </b-button>
    <b-row>
      <b-col v-for="(item, index) in page.images" :key="index" class="shadow-lg mb-1 bg-white mx-1 rounded" sm="12" md="7" lg="5" xl="3">
        <b-card :img-src="item.value" img-top img-alt="card img">
          <!--  -->
          <b-card-title>
            <h3>Campo: {{ item.field }}</h3>
          </b-card-title>

          <!--  -->
          <b-button :disabled="$apiCall.state" block variant="outline-info" @click="edit(item)"> Editar </b-button>
        </b-card>
      </b-col>
    </b-row>
    <validation-observer ref="simpleRules" #default="{ invalid }">
      <b-modal hide-footer centered size="lg" v-model="modalEdit">
        <div v-if="editImage.value" class="img-container mb-1 text-center">
          <img id="img" class="img" :src="editImage.value || ''" />
        </div>
        <b-col>
          <input id="cameraImage" style="display: none" type="file" accept="image/* capture=camera" @change="getFile()" />

          <label :disabled="$apiCall.state" class="lbl mb-1 btn-block" for="cameraImage">
            <span>Seleccionar </span>
          </label>

          <b-card v-if="editImage.value">
            <b-card-title>Calidad: {{ quality }}%</b-card-title>

            <vue-slider v-model="quality" :min="10" :max="100" :interval="1" />
            <b-form-radio-group id="format-options" v-model="format" class="radio mt-1">
              <b-form-radio v-for="option in formatOptions" :key="option.value" :value="option.value" :class="`custom-control-${option.radioVariant}`">
                {{ option.text }}
              </b-form-radio>
            </b-form-radio-group>
          </b-card>
        </b-col>
        <b-form-group label="Campo en la página" label-for="elem.field">
          <validation-provider #default="{ errors }" name="content" rules="required|max:50">
            <b-form-input v-model="editImage.field" name="elem.field" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-row class="mb-2"> </b-row>
        <b-row>
          <b-col md="6">
            <b-button
              class="mb-2"
              :disabled="$apiCall.state || invalid || !editImage.value"
              block
              variant="success"
              type="button"
              @click="publishImage()"
            >
              Publicar imagen
            </b-button>
          </b-col>
          <b-col md="6">
            <b-button :disabled="$apiCall.state || !editImage._id" block variant="outline-danger" type="button" @click="confirmDelete = true">
              Eliminar
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal @backdrop="cancel()" @cancel="cancel()" hide-footer centered title="Eliminar imagen" v-model="confirmDelete">
        <h2>Se eliminará la imagen de la página</h2>

        <b-row>
          <b-col md="6" class="mt-1">
            <b-button class="mb-2" :disabled="$apiCall.state || invalid" block variant="outline-danger" @click="deleteImage(editImage)" type="button">
              Eliminar
            </b-button>
          </b-col>
          <b-col md="6" class="mt-1">
            <b-button class="mb-2" :disabled="$apiCall.state" block variant="outline-info" type="button" @click="confirmDelete = false">
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BCardText,
  BFormInput,
  BCardTitle,
  BModal,
  BCardImg,
  BButton,
  BCol,
  BRow,
  BFormRadio,
  BFormRadioGroup
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'

localize('es')

export default {
  components: {
    BModal,
    BCard,
    BCardText,
    BCardTitle,
    BFormInput,
    BFormGroup,
    BCardImg,
    BButton,
    BCol,
    BRow,
    VueSlider,
    BFormRadio,
    BFormRadioGroup,

    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  props: {
    pageName: {
      type: String,
      default: 'home'
    }
  },
  data: () => ({
    indexImage: '',
    quality: 90,
    format: 'WEBP',
    formatOptions: [
      { text: 'WebP', value: 'WEBP', radioVariant: 'primary' },
      { text: 'Avif', value: 'AVIF', radioVariant: 'primary' }
    ],
    modalEdit: false,
    required,
    confirmDelete: false,
    image: {
      _id: '',
      field: '',
      value: '',
      alt: ''
    },
    editImage: {},
    img: '',
    selectedImage: null
  }),

  computed: {
    page() {
      return this.$store.getters['storyStore/getPage'](this.pageName)
    },
    story() {
      return this.$store.state.storyStore.story
    }
  },
  methods: {
    cancel() {
      this.editImage = ''
      this.modalEdit = false
    },
    deleteImage(input) {
      this.$store
        .dispatch('storyStore/deleteImage', { imageId: input._id, storyId: this.story._id, pageId: this.page._id, value: input.value })
        .then(res => {
          if (res) {
            this.$swal({
              icon: 'success',
              title: 'Datos borrados!',
              text: 'Se eliminó una imagen!',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          }
          this.modalEdit = false
          this.confirmDelete = false
        })
    },
    publishImage() {
      this.$store
        .dispatch('storyStore/publishImage', {
          storyId: this.story._id,
          pageId: this.page._id,
          image: this.editImage,
          pageName: this.pageName,
          options: { quality: this.quality, format: this.format }
        })
        .then(res => {
          if (res) {
            this.$swal({
              icon: 'success',
              title: 'Datos guardados!',
              text: 'Publicación de una imagen!',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          }
          this.modalEdit = false
        })
    },

    edit(item = null) {
      this.editImage =
        item ||
        new Object({
          _id: '',
          field: '',
          value: '',
          alt: ''
        })
      this.modalEdit = true
    },
    async getFile() {
      const [files] = document.getElementById('cameraImage').files

      this.render(files)
    },
    async render(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', e => {
        this.editImage.value = e.target.result
      })
    }
  }
}
</script>

<style scoped>
.radio :hover {
  cursor: pointer;
}
.lbl {
  cursor: pointer;
  color: #0cdbf2;
  font-size: 1rem;
  padding: 0.786rem 1.5rem;
  line-height: 1;
  border-radius: 7px;
  border: 1px solid #0cdbf2;
  font-weight: 500;
  text-align: center;
}
.buttonAdd {
  width: 100px;
  height: 100px;
}
.iconImage {
  width: 30px;
  height: 30px;
}
.img {
  height: 350px;
}

.img-container {
  width: 100%;
  height: 600px;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
